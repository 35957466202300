/*
이 파일은 SVG 코드를 React 컴포넌트로 변환한 것입니다.
Material-UI의 SvgIcon 컴포넌트를 사용하여 스타일을 적용합니다.
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
const Logo: React.FC<SvgIconProps> = props => {
  return <svg {...props} viewBox="0 0 1013 303" data-sentry-element="svg" data-sentry-component="Logo" data-sentry-source-file="logo.tsx">
            <path d="M240.899 50.5673H277.128V278.39C277.128 291.991 288.152 303.034 301.729 303.034C315.306 303.034 326.33 291.991 326.33 278.39V50.5673H362.559C376.506 50.5673 387.798 39.2553 387.798 25.2837C387.798 11.312 376.506 0 362.559 0H240.933C226.986 0 215.694 11.312 215.694 25.2837C215.694 39.2553 226.986 50.5673 240.933 50.5673H240.899Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
            <path d="M86.0354 0C38.6151 0 0 39.693 0 88.476V214.524C0 263.307 38.6151 303 86.0354 303C133.456 303 172.071 263.307 172.071 214.524V88.476C172.104 39.693 133.489 0 86.0354 0ZM122.937 214.524C122.937 235.431 106.402 252.433 86.069 252.433C65.7364 252.433 49.2015 235.431 49.2015 214.524V88.476C49.2015 67.569 65.7364 50.5673 86.069 50.5673C106.402 50.5673 122.937 67.569 122.937 88.476V214.524Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
            <path d="M554.424 214.524C554.424 235.431 537.889 252.433 517.557 252.433C497.224 252.433 480.689 235.431 480.689 214.524V24.644C480.689 11.0427 469.666 0 456.089 0C442.511 0 431.488 11.0427 431.488 24.644V214.558C431.488 263.341 470.103 303.034 517.523 303.034C564.944 303.034 603.559 263.341 603.559 214.558V24.644C603.559 11.0427 592.535 0 578.958 0C565.38 0 554.357 11.0427 554.357 24.644V214.558L554.424 214.524Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
            <path d="M645.098 278.726C645.098 272.262 647.45 266.64 652.189 261.893C656.928 257.146 662.574 254.789 669.161 254.789C675.748 254.789 681.394 257.179 686.133 261.893C690.871 266.64 693.224 272.296 693.224 278.894C693.224 285.493 690.838 291.317 686.133 296.064C681.528 300.677 675.849 303 669.161 303C662.473 303 656.625 300.677 652.021 296.064C647.417 291.452 645.098 285.661 645.098 278.726Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
            <path d="M1013 24.7114C1013 11.1009 1001.99 0.0673828 988.416 0.0673828C974.839 0.0673828 963.832 11.1009 963.832 24.7114V278.356C963.832 291.967 974.839 303 988.416 303C1001.99 303 1013 291.967 1013 278.356V24.7114Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
            <path d="M925.755 272.498L860.253 18.3479C857.498 7.6082 847.852 0.100536 836.762 0.0332031H836.661C825.638 0.0332031 815.992 7.43987 813.136 18.1459L745.215 272.262C741.081 287.749 752.743 302.966 768.74 302.966C779.763 302.966 789.409 295.559 792.265 284.887L805.977 230.482H865.429L878.603 284.685C881.393 295.458 891.072 303 902.196 303C918.126 303 929.754 287.951 925.788 272.498H925.755ZM818.345 181.261L836.224 110.325L853.465 181.261H818.378H818.345Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
        </svg>;
};
export default Logo;