import { lazy, useEffect } from 'react';
import { useSetAtom } from 'jotai';
import Logo from '@/public/icon/logo';
import { Android, Apple, Microsoft, Terminal } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Btn } from '@/components/layout/Btn';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
const Slogan = lazy(() => import('@/public/etc/slogan'));
export default function Layout() {
  const router = useRouter();
  useEffect(() => {
    router.prefetch('/signin');
  }, []);
  return <div className="welcome-container min-h-screen flex flex-col" data-sentry-component="Layout" data-sentry-source-file="layout.tsx">
            <div className="main flex-grow flex justify-center">
                <div className="max-w-[700px] w-full px-10 content-area">
                    <Top data-sentry-element="Top" data-sentry-source-file="layout.tsx" />
                    <Content data-sentry-element="Content" data-sentry-source-file="layout.tsx" />
                </div>
            </div>
            <div className="footer flex justify-center py-10 bg-text-color dark:bg-[#1e1e1e] mt-[120px] text-[#949494]">
                <div className="max-w-[700px] w-full px-10">
                    <Footer data-sentry-element="Footer" data-sentry-source-file="layout.tsx" />
                </div>
            </div>
        </div>;
}
function Top() {
  const btnStyle = {
    maxWidth: '348px',
    width: '100%',
    borderRadius: '3px'
  };
  return <div data-sentry-component="Top" data-sentry-source-file="layout.tsx">
            <div style={{
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      marginTop: '43px'
    }}>
                <div></div>
                <div></div>
                <Link href="/signin" className="text-[15px]" data-sentry-element="Link" data-sentry-source-file="layout.tsx">
                    Login
                </Link>
            </div>

            <div className="mt-[70px] flex justify-center">
                <Logo className="fill-text-color" height="35" data-sentry-element="Logo" data-sentry-source-file="layout.tsx"></Logo>
            </div>

            <div className="text-center mt-[73px] mb-[20px] text-color text-[15px]">
                OTU는 <span className="font-bold">메모장+α</span> 입니다.
            </div>

            <div className="text-center">
                <Link href="/signin" className="inline-flex justify-center items-center h-[48px] bg-text-color inverted-text-color text-[17px] font-bold hover:opacity-70" style={btnStyle} prefetch={true} data-sentry-element="Link" data-sentry-source-file="layout.tsx">
                    시작하기
                </Link>
            </div>

            <div className="mt-[55px]">
                <div className="flex flex-col items-center gap-[11px]">
                    <div className="text-[13px] mb-[4px] opacity-50" style={btnStyle}>
                        앱 다운로드 (준비중)
                    </div>
                    <Btn onClick={() => {
          alert('곧 출시합니다.');
        }} data-sentry-element="Btn" data-sentry-source-file="layout.tsx">
                        <Apple className="w-[20px]" data-sentry-element="Apple" data-sentry-source-file="layout.tsx" />
                        App Store
                    </Btn>

                    <Btn onClick={() => {
          alert('곧 출시합니다.');
        }} data-sentry-element="Btn" data-sentry-source-file="layout.tsx">
                        <Android className="w-[20px] mr-1" data-sentry-element="Android" data-sentry-source-file="layout.tsx" />
                        Google Play
                    </Btn>
                    <div className="h-4"></div>
                    <Btn onClick={() => {
          alert('곧 출시합니다.');
        }} data-sentry-element="Btn" data-sentry-source-file="layout.tsx">
                        <Terminal className="mr-1 w-[20px]" data-sentry-element="Terminal" data-sentry-source-file="layout.tsx" />
                        CLI
                    </Btn>

                    <Btn onClick={() => {
          alert('곧 출시합니다.');
        }} data-sentry-element="Btn" data-sentry-source-file="layout.tsx">
                        Desktop app
                    </Btn>
                </div>
            </div>

            <div className="flex justify-center mt-[90px] text-[25px]">
                <Slogan className="fill-text-color" width="200" data-sentry-element="Slogan" data-sentry-source-file="layout.tsx"></Slogan>
            </div>
        </div>;
}
function Content() {
  const accordionStyle = {
    boxShadow: 'none',
    margin: 0,
    borderBottom: '1px solid var(--border-color)',
    '&:before': {
      display: 'none'
    },
    '&.Mui-expanded': {
      margin: 0,
      borderBottom: '1px solid var(--border-color)'
    },
    '&:last-of-type': {
      borderBottom: 'none'
    }
  };
  const iconStyle = {
    fontSize: '1.2rem',
    opacity: 0.5
  };
  return <>
            <div className="mt-[94px] ">
                <Accordion sx={accordionStyle} data-sentry-element="Accordion" data-sentry-source-file="layout.tsx">
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={iconStyle} />} data-sentry-element="AccordionSummary" data-sentry-source-file="layout.tsx">
                        <div className="text-[19px] text-color">기능</div>
                    </AccordionSummary>
                    <AccordionDetails data-sentry-element="AccordionDetails" data-sentry-source-file="layout.tsx">
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            <span className="text-[15px] font-bold">· 크로스 플랫폼 메모장</span>
                            <div className="ml-2">다양한 기기에서 동일한 경험을 제공합니다.</div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            <span className="text-[15px] font-bold">· AI 챗봇</span>
                            <div className="ml-2">내 기록을 바탕으로 AI가 답변해줍니다.</div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            <span className="text-[15px] font-bold">· 강력한 편집 기능</span>
                            <div className="ml-2">
                                워드프로세서 수준의 고급 편집 기능을 지원합니다.
                            </div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            <span className="text-[15px] font-bold">· 빠른 기록</span>
                            <div className="ml-2">
                                순간적으로 메모하고 바로 일상으로 돌아가세요.
                            </div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            <span className="text-[15px] font-bold">· 자동 제목 생성</span>
                            <div className="ml-2">
                                제목 고민은 이제 그만, 자동으로 생성해 드립니다.
                            </div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            <span className="text-[15px] font-bold">· OCR</span>
                            <div className="ml-2">
                                이미지의 텍스트를 추출하여 메모로 저장합니다.
                            </div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            <span className="text-[15px] font-bold">· 빠른 로딩</span>
                            <div className="ml-2">클릭과 동시에 즉시 메모가 열립니다.</div>
                        </Pa>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionStyle} data-sentry-element="Accordion" data-sentry-source-file="layout.tsx">
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={iconStyle} />} data-sentry-element="AccordionSummary" data-sentry-source-file="layout.tsx">
                        <div className="text-[19px] text-color">가격정책</div>
                    </AccordionSummary>
                    <AccordionDetails data-sentry-element="AccordionDetails" data-sentry-source-file="layout.tsx">
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">메모 작성은 무료입니다.</Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            매달 AI, OCR, 파일업로드 기능을 사용할 수 있는 무료 크래딧이 제공됩니다.
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">유료 구독을 하면 매월 갱신되는 충분한 양의 크래딧을 제공합니다.</Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">정확한 가격은 정식 오픈 시 공지 할 예정입니다. ($5 예상) </Pa>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionStyle} data-sentry-element="Accordion" data-sentry-source-file="layout.tsx">
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={iconStyle} />} data-sentry-element="AccordionSummary" data-sentry-source-file="layout.tsx">
                        <div className="text-[19px] text-color">OTU 소개</div>
                    </AccordionSummary>
                    <AccordionDetails data-sentry-element="AccordionDetails" data-sentry-source-file="layout.tsx">
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">OTU는 메모장입니다. </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">거기에 AI를 보탰습니다.</Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">더 쉽게 넣고, 더 유용하게 꺼낼 수 있습니다.</Pa>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionStyle} data-sentry-element="Accordion" data-sentry-source-file="layout.tsx">
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={iconStyle} />} data-sentry-element="AccordionSummary" data-sentry-source-file="layout.tsx">
                        <div className="text-[19px] text-color">오픈튜토리얼스 소개</div>
                    </AccordionSummary>
                    <AccordionDetails data-sentry-element="AccordionDetails" data-sentry-source-file="layout.tsx">
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            OTU는 오픈튜토리얼스에서 개발하고 운영하는 지식 공유 및 메모
                            서비스입니다.
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            오픈튜토리얼스는 2016년 4월 15일 한국에서 설립된 비영리 단체로, 기술을
                            활용하여 지식의 자유로운 공유를 촉진하고자 합니다. 우리의 목표는 “남이
                            할 수 있는 걸 나도 할 수 있게, 내가 할 수 있는 걸 남도 할 수 있게”라는
                            슬로건 아래, 서로 배움과 성장을 지원하는 것입니다.
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            다양한 분야의 전문가들, 특히 소프트웨어 엔지니어들이 모여
                            오픈튜토리얼스의 서비스 개발과 운영을 이끌고 있으며, 주요 서비스로는
                            지식 공유 플랫폼{' '}
                            <a className="underline" href="https://opentutorials.org">
                                opentutorials.org
                            </a>
                            와 메모 서비스{' '}
                            <a className="underline" href="https://otu.ai">
                                otu.ai
                            </a>{' '}
                            등이 있습니다.
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="layout.tsx">
                            오픈튜토리얼스는 비영리 단체로서 주주가 존재하지 않으며, 잉여 수익은
                            서비스 운영비를 제외하고 사용자와 사회를 위한 사업에 재투자됩니다.
                            이익을 위해 서비스와 사용자의 데이터를 매각하지 않을 것을 약속 합니다.
                        </Pa>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="mt-[120px] text-center text-[15px]">
                남이 할 수 있는 걸 나도 할 수 있게 <br />
                내가 할 수 있는 걸 남도 할 수 있게 <br />
                <div className="mt-[15px] text-[12px] opacity-40">OPENTUTORIALS</div>
            </div>
        </>;
}
function Footer() {
  return <>
            <div className="text-[13px]">
                <div>비영리단체 오픈튜토리얼스</div>
                <div>단체 고유번호 619-82-61277</div>
                <div>경기도 의정부시 새롬안길 105</div>
            </div>
        </>;
}
function Pa({
  children
}: {
  children: React.ReactNode;
}) {
  return <div className="pt-0 py-3 text-[15px] opacity-70 text-color" data-sentry-component="Pa" data-sentry-source-file="layout.tsx">{children}</div>;
}