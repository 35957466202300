'use client';

export function Btn({
  children,
  onClick,
  disabled = false
}: {
  children: React.ReactNode;
  onClick: (event: any) => void;
  disabled?: boolean;
}) {
  return <button onClick={evt => {
    onClick(evt);
  }} className="h-[48px] border-color border-[1px] text-color hover:bg-[var(--focus-bg-color)] text-[14px] font-bold flex justify-center max-w-[348px] w-full items-center gap-1 disabled:opacity-30 disabled:cursor-not-allowed" disabled={disabled} data-sentry-component="Btn" data-sentry-source-file="Btn.tsx">
            {children}
        </button>;
}